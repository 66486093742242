.accordion-header {
  .accordion-button {
    line-height: 0.5rem;
  }
}
.accordion-item {
  border-radius: var(--bs-accordion-border-radius);
  border-top: 1px solid var(--bs-primary) !important;
  margin-bottom: 1rem !important;
}
.accordion-button {
  &:not(.collapsed) {
    box-shadow: none;
  }
  &.collapsed:hover {
    background-color: var(--bs-primary);
    color: var(--bs-white);
    .regions {
      border-color: var(--bs-white) !important;
    }
    &:after {
      background-image: var(--bs-accordion-btn-icon);
    }
  }
  &:after {
    background-image: var(--bs-accordion-btn-active-icon);
  }
}
