// Overrides
body {
    font-family: 'proxima-nova', sans-serif;
    overscroll-behavior-x: none;
}

@media screen and (max-width: 992px) {
    body, .container-fluid {
        overflow-x: hidden
    }
}

h1, .h1, h2, .h2, h3, .h3, .title {
    font-family: 'proxima-nova', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
}

a, .btn-link {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

a.text-white-hover,
.text-white-hover {
    &:hover {
        color: $white !important;
    }
}
.mobile a.btn-link {
    text-decoration: underline;
}
.btn:focus, .btn.focus {
    box-shadow: none;
}
.nounderline {
    text-decoration: none !important
}

.o-9 {
    opacity: 0.9 !important;
}
:root {
    --tertiary: $tertiary;
    --quaternary: $quaternary;
};

.dropdown-menu {
    min-width: 17rem;
}

.no-focus-shadow:focus {
    box-shadow: none;
}
.nav-link:hover {
    text-decoration: underline;
}

.red-color {
    color: red;
}
.red-border {
    border: 3px solid red !important;
}

iframe {
    max-width: 100%;
}

.btn-primary.inverse {
    background-color: $white;
    border: 1px solid $primary;
    padding: 8px 16px;
    color: $primary;
    transition: opacity .5s;
    &:active {
        background-color: $gray;
    };
    &:hover {
        text-decoration: none;
        &:not([disabled]) {
            background-color: $primary;
            border-color: $white;
            color: $white;
        }
    };
    &.disabled {
        border-color: $disabled;
        color: $disabled;
        opacity: .2;
    }
}

.dropdown-toggle:after {
    vertical-align: middle;
}

.locked {
    overflow: hidden;
}

.footer-bg-color {
    background-color: $footer-bg;
}

.gm-style iframe + div {
    border: none !important;
}

.rotate-ninety {
    @media (min-width: 992px) {
        --bs-accordion-btn-icon-transform: rotate(-90deg);
    }
}