$theme-colors: map-merge($theme-colors, (
    "tertiary": $tertiary,
    "quaternary": $quaternary,
    "primary-light": $primary-light,
    "primary-light-hover": $primary-light-hover,
    "primary-dark": $primary-dark,
    "primary-dark-hover": $primary-dark-hover,
    "primary-dark-alt": $primary-dark-alt,
    "secondary-light": $secondary-light,
    "secondary-light-hover": $secondary-light-hover,
    "secondary-dark": $secondary-dark,
    "secondary-dark-hover": $secondary-dark-hover,
    "light-gray": $gray,
    "dark-gray": $gray-dark,
    "white": $white,
    "black": $black,
)); 

@each $colorName, $color in $colors {
    .fill-#{$colorName} {
      fill: $color
    }
    .stroke-#{$colorName} {
        stroke: $color
    }
}

@each $colorName, $color in $theme-colors {
    .fill-#{$colorName} {
      fill: $color
    }
    .stroke-#{$colorName} {
        stroke: $color
    }
}

:root {
    --input-border-color: #{$input-border-color};
    --input-focus-border-color: #{$input-focus-border-color};
    --input-focus-outline: 0;
    --input-focus-box-shadow: #{$input-focus-box-shadow};
    --input-border-radius: #{$input-border-radius};
}