.modal.drawer {
  transition-delay: .5s;

  .modal-dialog {
    height: 100%;
    margin: 0 0 0 auto;
    max-width: $drawer-width;
    @media (max-width: 650px) {
      max-width: 80%;
    }

    .modal-content {
      height: 100%;
      border: none;
      border-radius: 0;
    }

    .modal-header {
      background-color: $modal-header-color;
    }

    .modal-body {
      max-height: calc(100vh - 100px);
      overflow-y: auto;
    }
  }

  &.wide .modal-dialog {
    max-width: $wide-drawer-width;
    @media (max-width: 1100px) {
      max-width: 90%;
    }
    @media (max-width: 650px) {
      max-width: 100%;
    }
  }

  &.full .modal-dialog {
    max-width: $full-drawer-width;
  }

  &.fade {
    .modal-dialog {
      transform: translate(100%, 0);
    }
  }

  &.show {
    transition-delay: 0s;

    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &.overlay + .modal-backdrop {
    opacity: 0;
  }

  .alert {
    border-radius: 0;
  }
}
