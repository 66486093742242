$lhr-blue:      #0077C0;
$lhr-dark-blue: #003C64;
$lhr-green:     #39B54A;
$lhr-orange:    #f6962f;


// scss-docs-start theme-color-variables
$primary:           $lhr-blue;
$secondary:         $lhr-green;
$tertiary:          $lhr-dark-blue;
$quaternary:        $lhr-orange;
$white:             #ffffff;
$black:             #000000;
$gray:              #cccccc;
$disabled:          #6c757d;
$marker-orange:     #f59530;
$input-btn-padding-x: 2rem;

$gray-dark:                 darken($gray, 20%);

$primary-light:             lighten($primary, 20%);
$primary-light-hover:       lighten($primary, 35%);
$primary-dark:              #00548B;
$primary-dark-alt:          #003C64;
$primary-dark-hover:        darken($primary, 25%);

$secondary-light:           lighten($primary, 20%);
$secondary-light-hover:     lighten($primary, 25%);
$secondary-dark:            darken($primary, 30%);
$secondary-dark-hover:      darken($primary, 35%);
$danger:                  rgb(220, 53, 69);

// Font Variables
$garamond: 'EB Garamond', serif;

// Footer Variables
$footer-bg: $tertiary;

:export {
    primary: $primary;
    primaryLight: $primary-light;
    primaryLightHover: $primary-light-hover;
    primaryDark: $primary-dark;
    primaryDarkAlt: $primary-dark-alt;
    primaryDarkHover: $primary-dark-hover;
    secondary: $secondary;
    tertiary: $tertiary;
    quaternary: $quaternary;
    markerOrange: $marker-orange;
    white: $white;
    black: $black;
    gray: $gray;
    grayDark: $gray-dark;
    offBlack: rgb(65, 66, 68);
    danger: $danger;
}

// Modal Drawer
$drawer-width:                            580px;
$wide-drawer-width:                       900px;
$full-drawer-width:                       100%;
$modal-header-color:                      #f5f5f5;

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xg: 1100px,
  xl: 1200px,
  xxl: 1400px
);

$form-select-bg-position: right .5rem center;

// Accordion
$accordion-body-padding-y: 0 1rem;
$accordion-border-color: var(--bs-primary);
$accordion-button-bg: transparent;
$accordion-button-color: var(--bs-primary);
$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: transparent;
$accordion-button-color: var(--bs-primary);
$accordion-icon-color: $white;